import React from 'react';
import { A11y } from 'swiper';
import Swiper from 'react-id-swiper';
import styled from 'styled-components';
import RecentProjectSlide from './RecentProjectsSlide';
import { motion } from 'framer-motion';
import 'swiper/css/swiper.css';

const SwiperWrapper = styled.div`
  position: relative;
  width: 92vw;
  max-width: 1445px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  }
`;

const MotionSwiperWrapper = motion(SwiperWrapper);

const itemVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
    },
  },
};

export default function RecentProjectsSlider({ recentProjects }) {
  const params = { slidesPerView: 'auto' };
  const slides = recentProjects.map((slide) => {
    return <RecentProjectSlide slide={slide} key={slide.id} />;
  });
  return (
    <MotionSwiperWrapper variants={itemVariants}>
      <Swiper modules={[A11y]} {...params}>
        {slides}
      </Swiper>
    </MotionSwiperWrapper>
  );
}
