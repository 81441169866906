import React from 'react';
import styled from 'styled-components';
import RecentProjectsSlider from '../components/RecentProjectsSlider';
import { MdChevronRight } from 'react-icons/md';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

const StyledRecentProjectSection = styled.section`
  position: relative;
  display: grid;

  .background-image {
    grid-area: 1/1;
    position: relative;
    max-height: 900px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        rgba(244, 244, 244, 1),
        rgba(244, 244, 244, 1) 2%,
        rgba(244, 244, 244, 0.5)
      );
      z-index: 2;
    }
  }
  .slider-text-section {
    width: 92vw;
    margin-left: auto;
    margin-right: auto;
    max-width: 1445px;
    .arrow-icon {
      position: relative;
      top: 3px;
    }
  }
`;
const StyledContent = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  grid-area: 1/1;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 768px) {
    padding-top: 5rem;
  }
`;
const MotionRecentProjectSection = motion(StyledRecentProjectSection);
const MotionRecentProjectsSlider = motion(RecentProjectsSlider);

const itemVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
    },
  },
};
export default function RecentProjectsSection({ recentProjects }) {
  return (
    <MotionRecentProjectSection>
      <StaticImage
        src="../images/recent-project-section-background.jpg"
        alt="Corrugated Metal Panel"
        placeholder="none"
        className="background-image"
      />
      <StyledContent>
        <motion.div className="content-wrapper">
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            transition={{ staggerChildren: 0.15 }}
            className="slider-text-section"
          >
            <motion.h2 variants={itemVariants}>Recent Projects</motion.h2>
            <motion.p variants={itemVariants}>
              We are constantly working on delivering high quality architectural
              sheet metal finishes
            </motion.p>
            <motion.p variants={itemVariants}>
              Drag to see more <MdChevronRight className="arrow-icon" />
            </motion.p>
            <MotionRecentProjectsSlider recentProjects={recentProjects} />
          </motion.div>
        </motion.div>
      </StyledContent>
    </MotionRecentProjectSection>
  );
}
