import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

const BrandSection = styled.section`
  background-color: var(--lightgrey);
  padding: 7.5rem 0;
  h3 {
    text-align: center;
    margin-bottom: 3rem;
  }
  .brand {
    width: 144px;
    @media (min-width: 768px) {
      width: auto;
    }
    display: flex;
    justify-content: center;
  }
  .brand-wrapper {
    width: 90%;
    max-width: 1445px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    gap: 3rem;
    justify-content: space-between;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
`;

const itemVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
    },
  },
};

const MotionBrandSection = motion(BrandSection);

export default function BrandsSection({ brands }) {
  const card = brands.map((brand, index) => {
    const image = getImage(brand.logoImage.asset.gatsbyImageData);
    return (
      <motion.div variants={itemVariants} className="brand" key={index}>
        <a
          href={brand.url}
          title={`Visit ${brand.name}'s website`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <GatsbyImage image={image} alt="Brand Logo" />
        </a>
      </motion.div>
    );
  });

  return (
    <MotionBrandSection
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.2 }}
      transition={{ staggerChildren: 0.15 }}
    >
      <motion.h3 variants={itemVariants}>Brands We Work With</motion.h3>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.2 }}
        transition={{ staggerChildren: 0.15 }}
        className="brand-wrapper"
      >
        {card}
      </motion.div>
    </MotionBrandSection>
  );
}
