import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import HeroSlider from '../components/HeroSlider';
import RecentProjectsSection from '../components/RecentProjectsSection';
const ContentSection = loadable(() => import('../components/ContentSection'));
const MapSection = loadable(() => import('../components/MapSection'));
import SEO from '../components/SEO';
import BrandsSection from '../components/BrandSection';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const PageContent = styled.div`
  background: var(--black);
`;
const MotionPageContent = motion(PageContent);

export default function HomePage({ data }) {
  return (
    <motion.div>
      <SEO title={'Homepage'} />
      <MotionPageContent
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
        <HeroSlider slideData={data.sanityHomePage.headerSlide} />
        <RecentProjectsSection recentProjects={data.sanityHomePage.projects} />
        <ContentSection contentData={data.sanityHomePage} />
        <BrandsSection brands={data.sanityHomePage.brands} />
        <MapSection />
      </MotionPageContent>
    </motion.div>
  );
}

export const { data } = graphql`
  query HomePageQuery {
    sanityHomePage(id: { eq: "-95be279d-356b-57af-a90a-e3451d43fc8a" }) {
      id
      headerSlide {
        subHeadingText
        largeHeadingText
        project {
          headerImage {
            asset {
              gatsbyImageData(placeholder: NONE)
            }
            altText
          }
          slug {
            current
          }
        }
      }
      contentsubHeading
      contentHeading
      projects {
        headerImage {
          asset {
            thumbnailImage: gatsbyImageData(width: 450, aspectRatio: 1.5)
          }
          altText
        }
        id
        description
        name
        slug {
          current
        }
        address {
          city
          state
        }
      }
      brands {
        name
        logoImage {
          asset {
            gatsbyImageData(height: 61)
          }
        }
        url
      }
    }
  }
`;
