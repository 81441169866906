import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledSlide = styled.div`
  width: 85%;
  max-width: 350px;
  padding-right: 11px;
  cursor: pointer;
  .project-link {
    height: 100%;
    text-decoration: none;
    color: var(--black);
    display: block;
    & :visited {
      color: var(--black);
    }
  }
  .image-wrapper {
    box-shadow: none;
    transition: box-shadow 0.6s cubic-bezier(0.43, 0.13, 0.23, 0.96);
    overflow: hidden;
    &:hover {
      box-shadow: 0.2em 0.2em 0.4em 0.2em rgba(0, 0, 0, 50%);
      transition: box-shadow 0.6s cubic-bezier(0.43, 0.13, 0.23, 0.96);

      .project-image {
        transform: scale(1.1);
        transition: transform 0.6s cubic-bezier(0.43, 0.13, 0.23, 0.96);
      }
    }
  }
  .project-image {
    width: 100%;
    height: auto;
    filter: none;
    display: block;
    transform: scale(1);
    transition: transform 0.6s cubic-bezier(0.43, 0.13, 0.23, 0.96);
  } 
    @media screen and (min-width: 760px) {
      width: 320px;
    }
  }
  .slide-content {
    margin-top: 2rem;
    h1 {
      font-size: 25px;
    }
  }
`;

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    text = text.substr(0, maxLength);
    text = `${text.substr(0, Math.min(text.length, text.lastIndexOf(' ')))}...`;
  }
  return text;
}

export default function RecentProjectSlide({ slide }) {
  const image = slide.headerImage.asset.thumbnailImage.images.fallback.src;
  const slideDescription = truncateText(slide.description, 180);
  return (
    <StyledSlide className="swiper-slide">
      <Link
        className="project-link"
        to={`/projects/${slide.slug.current}`}
        title={`View ${slide.name} Details`}
      >
        <div className="image-wrapper">
          <img
            className="project-image"
            src={image}
            alt={slide.headerImage.altText}
          />
        </div>
        <div className="background-wrapper" />
        <div className="slide-content-wrapper">
          <div className="slide-content">
            <h1>{slide.name}</h1>
            <p className="sub-text">{slideDescription}</p>
          </div>
        </div>
      </Link>
    </StyledSlide>
  );
}
