import React from 'react';
import styled from 'styled-components';
import { A11y } from 'swiper';
import Swiper from 'react-id-swiper';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import 'swiper/css/swiper.css';
import slide1Small from '../../images/hero-slider/project-slide-1-415-700.jpg';
import slide2Small from '../../images/hero-slider/project-slide-2-415-700.jpg';
import slide3Small from '../../images/hero-slider/project-slide-3-415-700.jpg';
import slide4Small from '../../images/hero-slider/project-slide-4-415-700.jpg';
import slide5Small from '../../images/hero-slider/project-slide-5-415-700.jpg';
import slide6Small from '../../images/hero-slider/project-slide-6-415-700.jpg';
import slide7Small from '../../images/hero-slider/project-slide-7-415-700.jpg';
import slide8Small from '../../images/hero-slider/project-slide-8-415-700.jpg';
import slide1MedSmall from '../../images/hero-slider/project-slide-1-768-700.jpg';
import slide2MedSmall from '../../images/hero-slider/project-slide-2-768-700.jpg';
import slide3MedSmall from '../../images/hero-slider/project-slide-3-768-700.jpg';
import slide4MedSmall from '../../images/hero-slider/project-slide-4-768-700.jpg';
import slide5MedSmall from '../../images/hero-slider/project-slide-5-768-700.jpg';
import slide6MedSmall from '../../images/hero-slider/project-slide-6-768-700.jpg';
import slide7MedSmall from '../../images/hero-slider/project-slide-7-768-700.jpg';
import slide8MedSmall from '../../images/hero-slider/project-slide-8-768-700.jpg';
import slide1Med from '../../images/hero-slider/project-slide-1-992-765.jpg';
import slide2Med from '../../images/hero-slider/project-slide-2-992-765.jpg';
import slide3Med from '../../images/hero-slider/project-slide-3-992-765.jpg';
import slide4Med from '../../images/hero-slider/project-slide-4-992-765.jpg';
import slide5Med from '../../images/hero-slider/project-slide-5-992-765.jpg';
import slide6Med from '../../images/hero-slider/project-slide-6-992-765.jpg';
import slide7Med from '../../images/hero-slider/project-slide-7-992-765.jpg';
import slide8Med from '../../images/hero-slider/project-slide-8-992-765.jpg';
import slide1MedLarge from '../../images/hero-slider/project-slide-1-1248-765.jpg';
import slide2MedLarge from '../../images/hero-slider/project-slide-2-1248-765.jpg';
import slide3MedLarge from '../../images/hero-slider/project-slide-3-1248-765.jpg';
import slide4MedLarge from '../../images/hero-slider/project-slide-4-1248-765.jpg';
import slide5MedLarge from '../../images/hero-slider/project-slide-5-1248-765.jpg';
import slide6MedLarge from '../../images/hero-slider/project-slide-6-1248-765.jpg';
import slide7MedLarge from '../../images/hero-slider/project-slide-7-1248-765.jpg';
import slide8MedLarge from '../../images/hero-slider/project-slide-8-1248-765.jpg';
import slide1Large from '../../images/hero-slider/project-slide-1-1640-765.jpg';
import slide2Large from '../../images/hero-slider/project-slide-2-1640-765.jpg';
import slide3Large from '../../images/hero-slider/project-slide-3-1640-765.jpg';
import slide4Large from '../../images/hero-slider/project-slide-4-1640-765.jpg';
import slide5Large from '../../images/hero-slider/project-slide-5-1640-765.jpg';
import slide6Large from '../../images/hero-slider/project-slide-6-1640-765.jpg';
import slide7Large from '../../images/hero-slider/project-slide-7-1640-765.jpg';
import slide8Large from '../../images/hero-slider/project-slide-8-1640-765.jpg';
import slide1ExtraLarge from '../../images/hero-slider/project-slide-1-2016-765.jpg';
import slide2ExtraLarge from '../../images/hero-slider/project-slide-2-2016-765.jpg';
import slide3ExtraLarge from '../../images/hero-slider/project-slide-3-2016-765.jpg';
import slide4ExtraLarge from '../../images/hero-slider/project-slide-4-2016-765.jpg';
import slide5ExtraLarge from '../../images/hero-slider/project-slide-5-2016-765.jpg';
import slide6ExtraLarge from '../../images/hero-slider/project-slide-6-2016-765.jpg';
import slide7ExtraLarge from '../../images/hero-slider/project-slide-7-2016-765.jpg';
import slide8ExtraLarge from '../../images/hero-slider/project-slide-8-2016-765.jpg';
import slide1Giant from '../../images/hero-slider/project-slide-1-4032-2268.jpg';
import slide2Giant from '../../images/hero-slider/project-slide-2-4032-2268.jpg';
import slide3Giant from '../../images/hero-slider/project-slide-3-4032-2268.jpg';
import slide4Giant from '../../images/hero-slider/project-slide-4-4032-2268.jpg';
import slide5Giant from '../../images/hero-slider/project-slide-5-4032-2268.jpg';
import slide6Giant from '../../images/hero-slider/project-slide-6-4032-2268.jpg';
import slide7Giant from '../../images/hero-slider/project-slide-7-4032-2268.jpg';
import slide8Giant from '../../images/hero-slider/project-slide-8-4032-2268.jpg';
import slide1SmallWebP from '../../images/hero-slider/project-slide-1-415-700.webp';
import slide2SmallWebP from '../../images/hero-slider/project-slide-2-415-700.webp';
import slide3SmallWebP from '../../images/hero-slider/project-slide-3-415-700.webp';
import slide4SmallWebP from '../../images/hero-slider/project-slide-4-415-700.webp';
import slide5SmallWebP from '../../images/hero-slider/project-slide-5-415-700.webp';
import slide6SmallWebP from '../../images/hero-slider/project-slide-6-415-700.webp';
import slide7SmallWebP from '../../images/hero-slider/project-slide-7-415-700.webp';
import slide8SmallWebP from '../../images/hero-slider/project-slide-8-415-700.webp';
import slide1MedSmallWebP from '../../images/hero-slider/project-slide-1-768-700.webp';
import slide2MedSmallWebP from '../../images/hero-slider/project-slide-2-768-700.webp';
import slide3MedSmallWebP from '../../images/hero-slider/project-slide-3-768-700.webp';
import slide4MedSmallWebP from '../../images/hero-slider/project-slide-4-768-700.webp';
import slide5MedSmallWebP from '../../images/hero-slider/project-slide-5-768-700.webp';
import slide6MedSmallWebP from '../../images/hero-slider/project-slide-6-768-700.webp';
import slide7MedSmallWebP from '../../images/hero-slider/project-slide-7-768-700.webp';
import slide8MedSmallWebP from '../../images/hero-slider/project-slide-8-768-700.webp';
import slide1MedWebP from '../../images/hero-slider/project-slide-1-992-765.webp';
import slide2MedWebP from '../../images/hero-slider/project-slide-2-992-765.webp';
import slide3MedWebP from '../../images/hero-slider/project-slide-3-992-765.webp';
import slide4MedWebP from '../../images/hero-slider/project-slide-4-992-765.webp';
import slide5MedWebP from '../../images/hero-slider/project-slide-5-992-765.webp';
import slide6MedWebP from '../../images/hero-slider/project-slide-6-992-765.webp';
import slide7MedWebP from '../../images/hero-slider/project-slide-7-992-765.webp';
import slide8MedWebP from '../../images/hero-slider/project-slide-8-992-765.webp';
import slide1MedLargeWebP from '../../images/hero-slider/project-slide-1-1248-765.webp';
import slide2MedLargeWebP from '../../images/hero-slider/project-slide-2-1248-765.webp';
import slide3MedLargeWebP from '../../images/hero-slider/project-slide-3-1248-765.webp';
import slide4MedLargeWebP from '../../images/hero-slider/project-slide-4-1248-765.webp';
import slide5MedLargeWebP from '../../images/hero-slider/project-slide-5-1248-765.webp';
import slide6MedLargeWebP from '../../images/hero-slider/project-slide-6-1248-765.webp';
import slide7MedLargeWebP from '../../images/hero-slider/project-slide-7-1248-765.webp';
import slide8MedLargeWebP from '../../images/hero-slider/project-slide-8-1248-765.webp';
import slide1LargeWebP from '../../images/hero-slider/project-slide-1-1640-765.webp';
import slide2LargeWebP from '../../images/hero-slider/project-slide-2-1640-765.webp';
import slide3LargeWebP from '../../images/hero-slider/project-slide-3-1640-765.webp';
import slide4LargeWebP from '../../images/hero-slider/project-slide-4-1640-765.webp';
import slide5LargeWebP from '../../images/hero-slider/project-slide-5-1640-765.webp';
import slide6LargeWebP from '../../images/hero-slider/project-slide-6-1640-765.webp';
import slide7LargeWebP from '../../images/hero-slider/project-slide-7-1640-765.webp';
import slide8LargeWebP from '../../images/hero-slider/project-slide-8-1640-765.webp';
import slide1ExtraLargeWebP from '../../images/hero-slider/project-slide-1-2016-765.webp';
import slide2ExtraLargeWebP from '../../images/hero-slider/project-slide-2-2016-765.webp';
import slide3ExtraLargeWebP from '../../images/hero-slider/project-slide-3-2016-765.webp';
import slide4ExtraLargeWebP from '../../images/hero-slider/project-slide-4-2016-765.webp';
import slide5ExtraLargeWebP from '../../images/hero-slider/project-slide-5-2016-765.webp';
import slide6ExtraLargeWebP from '../../images/hero-slider/project-slide-6-2016-765.webp';
import slide7ExtraLargeWebP from '../../images/hero-slider/project-slide-7-2016-765.webp';
import slide8ExtraLargeWebP from '../../images/hero-slider/project-slide-8-2016-765.webp';
import slide1GiantWebP from '../../images/hero-slider/project-slide-1-4032-2268.webp';
import slide2GiantWebP from '../../images/hero-slider/project-slide-2-4032-2268.webp';
import slide3GiantWebP from '../../images/hero-slider/project-slide-3-4032-2268.webp';
import slide4GiantWebP from '../../images/hero-slider/project-slide-4-4032-2268.webp';
import slide5GiantWebP from '../../images/hero-slider/project-slide-5-4032-2268.webp';
import slide6GiantWebP from '../../images/hero-slider/project-slide-6-4032-2268.webp';
import slide7GiantWebP from '../../images/hero-slider/project-slide-7-4032-2268.webp';
import slide8GiantWebP from '../../images/hero-slider/project-slide-8-4032-2268.webp';

const StyledSlide = styled.div`
  display: grid;
  height: 100%;
  max-height: 100vh;

  .slide-content-wrapper {
    grid-area: 1/1;
    z-index: 10;
    width: 85%;
    height: 100%;
    max-width: 1280px;
    display: grid;
    place-items: center;
    position: relative;
    margin: 0 auto;
    max-height: 100vh;
  }
  .slide-content {
    max-width: 1280px;
    height: 33%;

    h1 {
      color: white;
      --minFontSize: 25px;
      --maxFontSize: 50px;
      --scaler: 5vw;
      font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
      width: 90%;

      @media screen and (min-width: 1000px) {
        width: 75%;
      }
    }
    .sub-text {
      color: white;
      font-size: 16px;
      padding-bottom: 2rem;
      width: 90%;

      @media screen and (min-width: 1000px) {
        width: 70%;
      }
    }
  }
  .background-wrapper {
    height: 100%;
    width: 100%;
    grid-area: 1/1;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.35);
    max-height: 100vh;
  }
`;

const ImageWrapper = styled.div`
  grid-area: 1 / 1;
  width: 100%;
  height: 100%;
  position: relative;
  max-height: 100vh;

  img {
    object-position: 50% 50%;
    object-fit: cover;
    width: auto;
    height: 100%;
    object-fit: cover;
  }
`;
const SwiperWrapper = styled(motion.div)`
  height: 100vh;

  .swiper-container {
    height: 100%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 25px;
    height: 25px;
    &:after {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .swiper-button-next {
    right: 0;
    &:after {
      content: '';
      background-image: url(../../images/hero-slider/chevron_right.svg);
      background-size: 25px 25px;
      width: 25px;
      height: 25px;
    }
  }
  .swiper-button-prev {
    left: 0px;
    &:after {
      content: '';
      background-image: url(../../images/hero-slider/chevron_left.svg);
      background-size: 25px 25px;
      width: 25px;
      height: 25px;
    }
  }
`;

export default function HeroSlider({ slideData }) {
  const params = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 7500,
      disableOnInteraction: true,
    },
    loop: true,
  };
  const slideImageData = [
    {
      smallWebP: slide8SmallWebP,
      medSmallWebP: slide8MedSmallWebP,
      medWebP: slide8MedWebP,
      medLargeWebP: slide8MedLargeWebP,
      largeWebP: slide8LargeWebP,
      extraLargeWebP: slide8ExtraLargeWebP,
      giantWebP: slide8GiantWebP,
      small: slide8Small,
      medSmall: slide8MedSmall,
      med: slide8Med,
      medLarge: slide8MedLarge,
      large: slide8Large,
      extraLarge: slide8ExtraLarge,
      giant: slide8Giant,
    },
    {
      smallWebP: slide1SmallWebP,
      medSmallWebP: slide1MedSmallWebP,
      medWebP: slide1MedWebP,
      medLargeWebP: slide1MedLargeWebP,
      largeWebP: slide1LargeWebP,
      extraLargeWebP: slide1ExtraLargeWebP,
      giantWebP: slide1GiantWebP,
      small: slide1Small,
      medSmall: slide1MedSmall,
      med: slide1Med,
      medLarge: slide1MedLarge,
      large: slide1Large,
      extraLarge: slide1ExtraLarge,
      giant: slide1Giant,
    },
    {
      smallWebP: slide2SmallWebP,
      medSmallWebP: slide2MedSmallWebP,
      medWebP: slide2MedWebP,
      medLargeWebP: slide2MedLargeWebP,
      largeWeb: slide2LargeWebP,
      extraLargeWebP: slide2ExtraLargeWebP,
      giantWebP: slide2GiantWebP,
      small: slide2Small,
      medSmall: slide2MedSmall,
      med: slide2Med,
      medLarge: slide2MedLarge,
      large: slide2Large,
      extraLarge: slide2ExtraLarge,
      giant: slide2Giant,
    },
    {
      smallWebP: slide3SmallWebP,
      medSmallWebP: slide3MedSmallWebP,
      medWebP: slide3MedWebP,
      medLargeWebP: slide3MedLargeWebP,
      largeWebP: slide3LargeWebP,
      extraLargeWebP: slide3ExtraLargeWebP,
      giantWebP: slide3GiantWebP,
      small: slide3Small,
      medSmall: slide3MedSmall,
      med: slide3Med,
      medLarge: slide3MedLarge,
      large: slide3Large,
      extraLarge: slide3ExtraLarge,
      giant: slide3Giant,
    },
    {
      smallWebP: slide4SmallWebP,
      medSmallWebP: slide4MedSmallWebP,
      medWebP: slide4MedWebP,
      medLargeWebP: slide4MedLargeWebP,
      largeWebP: slide4LargeWebP,
      extraLargeWebP: slide4ExtraLargeWebP,
      giantWebP: slide4GiantWebP,
      small: slide4Small,
      medSmall: slide4MedSmall,
      med: slide4Med,
      medLarge: slide4MedLarge,
      large: slide4Large,
      extraLarge: slide4ExtraLarge,
      giant: slide4Giant,
    },
    {
      smallWebP: slide5SmallWebP,
      medSmallWebP: slide5MedSmallWebP,
      medWebP: slide5MedWebP,
      medLargeWebP: slide5MedLargeWebP,
      largeWebP: slide5LargeWebP,
      extraLargeWebP: slide5ExtraLargeWebP,
      giantWebP: slide5GiantWebP,
      small: slide5Small,
      medSmall: slide5MedSmall,
      med: slide5Med,
      medLarge: slide5MedLarge,
      large: slide5Large,
      extraLarge: slide5ExtraLarge,
      giant: slide5Giant,
    },
    {
      smallWebP: slide6SmallWebP,
      medSmallWebP: slide6MedSmallWebP,
      medWebP: slide6MedWebP,
      medLargeWebP: slide6MedLargeWebP,
      largeWebP: slide6LargeWebP,
      extraLargeWebP: slide6ExtraLargeWebP,
      giantWebP: slide6GiantWebP,
      small: slide6Small,
      medSmall: slide6MedSmall,
      med: slide6Med,
      medLarge: slide6MedLarge,
      large: slide6Large,
      extraLarge: slide6ExtraLarge,
      giant: slide6Giant,
    },
    {
      smallWebP: slide7SmallWebP,
      medSmallWebP: slide7MedSmallWebP,
      medWebP: slide7MedWebP,
      medLargeWebP: slide7MedLargeWebP,
      largeWebP: slide7LargeWebP,
      extraLargeWebP: slide7ExtraLargeWebP,
      giantWebP: slide7GiantWebP,
      small: slide7Small,
      medSmall: slide7MedSmall,
      med: slide7Med,
      medLarge: slide7MedLarge,
      large: slide7Large,
      extraLarge: slide7ExtraLarge,
      giant: slide7Giant,
    },
  ];

  const slideDataWImage = slideData.map((slide, index) => ({
    ...slide,
    slideImages: slideImageData[index],
  }));
  const slidesArray = slideDataWImage.map((slide, index) => (
    <StyledSlide
      key={`slide-${index}`}
      className={`swiper-slide slide-${index}`}
    >
      <ImageWrapper>
        <picture>
          <source
            type="image/webp"
            srcSet={slide.slideImages.giantWebP}
            media="(min-width: 2016px) or (min-height: 1000px)"
          />
          <source
            type="image/webp"
            srcSet={slide.slideImages.extraLargeWebP}
            media="(min-width: 1640px) and (max-height: 999px)"
          />
          <source
            type="image/webp"
            srcSet={slide.slideImages.largeWebP}
            media="(min-width: 1248px) and (max-height: 999px)"
          />
          <source
            type="image/webp"
            srcSet={slide.slideImages.medLargeWebP}
            media="(min-width: 992px) and (max-height: 999px)"
          />
          <source
            type="image/webp"
            srcSet={slide.slideImages.medWebP}
            media="(min-width: 768px) and (max-height: 999px)"
          />
          <source
            type="image/webp"
            srcSet={slide.slideImages.medSmallWebP}
            media="(min-width: 415px) and (max-height: 999px)"
          />
          <source
            srcSet={slide.slideImages.giant}
            media="(min-width: 2016px) or (min-height: 1000px)"
          />
          <source
            srcSet={slide.slideImages.extraLarge}
            media="(min-width: 1640px) and (max-height: 999px)"
          />
          <source
            srcSet={slide.slideImages.large}
            media="(min-width: 1248px) and (max-height: 999px)"
          />
          <source
            srcSet={slide.slideImages.medLarge}
            media="(min-width: 992px) and (max-height: 999px)"
          />
          <source srcSet={slide.slideImages.med} media="(min-width: 768px)" />
          <source
            srcSet={slide.slideImages.medSmall}
            media="(min-width: 415px) and (max-height: 999px)"
          />
          <img
            src={slide.slideImages.small}
            alt={slide.project.headerImage.altText}
          />
        </picture>
      </ImageWrapper>
      <div className="background-wrapper" />
      <div className="slide-content-wrapper">
        <div className="slide-content">
          <h1>{slide.largeHeadingText}</h1>
          <p className="sub-text">{slide.subHeadingText}</p>
          <Link className="btn" to={`/projects/${slide.project.slug.current}`}>
            View Project
          </Link>
        </div>
      </div>
    </StyledSlide>
  ));
  const itemVariants = {
    offscreen: {
      y: 300,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        ease: [0.19, 1, 0.22, 1],
        duration: 1.5,
      },
    },
  };

  const MotionSwiperWrapper = motion(SwiperWrapper);
  return (
    <MotionSwiperWrapper
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.25 }}
      variants={itemVariants}
    >
      <Swiper modules={[A11y]} {...params}>
        {slidesArray}
      </Swiper>
    </MotionSwiperWrapper>
  );
}
